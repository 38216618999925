import { React, useState, useEffect, useRef, } from "react";
import './Carousel.css';
import image1 from '../../assets/images/IMG_6466.png';
import image2 from '../../assets/images/20230506_203147.jpg';
import image3 from '../../assets/images/20230507_010321.jpg';
import image4 from '../../assets/images/IMG_1208.JPG';
import image5 from '../../assets/images/IMG_1232.JPG';
import image6 from '../../assets/images/IMG_1262.JPG';
import image7 from '../../assets/images/PXL_20230507_191357203.jpg';
import image8 from '../../assets/images/PXL_20230507_191923852.jpg';
import image9 from '../../assets/images/PXL_20230507_193632055.jpg';
import { default as anime } from '../../../node_modules/animejs/lib/anime.es.js';
import Racoon5 from "../../assets/graphics/racoon5.png"
import Widget from '../../components/widget/Widget';

function Carousel() {

    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9];

    return (
        <div className="carousel">
            <div className="carousel-container" id="carouselComponent">
                <div className="middlegraphic-content">
                    <h1 className="middlegraphic-title">A Look At <span style={{ color: "#92f7a8" }}>2023</span>...</h1>
                </div>
                <ImageCarousel images={images} />
                <div className="carousel-graphics" style={{ background: `url(${Racoon5}) center/cover` }}>
                </div>
                <div style={{ position: "absolute", zIndex: "0", width: "25%", height: "25%", background: "rgba(106, 170, 255, 0.40)", filter: "blur(300px)", right: "155px", marginTop: "10px" }}>
                </div>
            </div>
            {/* <Widget variant="SquareBlue" style={{ right: 125, marginTop: "325px" }} /> */}
            {/* <Widget variant="SquareYellow" style={{ left: 125, marginTop: "55px" }} /> */}
        </div>
    );
}

let lastTime = -1;
let accumulateTime = -1;
const MAX_SPEED = 2;

const ImageCarousel = ({ images }) => {
    const tripledImages = images.concat(images, images);
    const [animator, setAnimator] = useState();
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [hoveredPerson, setHoveredPerson] = useState(null);
    const [accel, setAccel] = useState(1);
    const requestRef = useRef();

    // https://codesandbox.io/s/anime-js-speed-adjustment-lm0ui?file=/src/index.js:158-166
    // https://animejs.com/documentation/#tick
    // https://css-tricks.com/using-requestanimationframe-with-react-hooks/
    const animate = (animator, accel, velocity, t) => {
        if (accumulateTime === -1) {
            accumulateTime = t;
        } else {
            const deltaT = t - lastTime;
            velocity = velocity += 0.05 * accel;
            velocity = Math.max(0, velocity);
            velocity = Math.min(MAX_SPEED, velocity);
            accumulateTime += deltaT * velocity;
        }
        lastTime = t;
        animator.tick(accumulateTime);
        requestRef.current = window.requestAnimationFrame((t) =>
            animate(animator, accel, velocity, t)
        );
    };

    useEffect(() => {
        if (animator) {
            const vel = accel === 1 ? 0 : MAX_SPEED;
            requestRef.current = window.requestAnimationFrame((t) =>
                animate(animator, accel, vel, t)
            );
        }
        // Cleanup animation frame listener
        return () => window.cancelAnimationFrame(requestRef.current);
    }, [animator, accel]);

    useEffect(() => {
        setAnimator(
            anime({
                targets: ['#anim-profiles'],
                easing: 'linear',
                loop: true,
                translateX: [-(124 * tripledImages.length), 0],
                duration: 4000 * tripledImages.length,
                autoplay: false,
            })
        );
    }, [setAnimator]);

    return (
        <div className="image-carousel">
            <div className="image-container" id='anim-profiles' style={{ willChange: 'transform' }}>
                {tripledImages.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Image ${index}`}
                        className={`slider-image`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Carousel;
