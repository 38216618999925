import React, { useState, useEffect } from "react";
import MetHacks from "../../assets/logos/methacks_small_white.PNG";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import "./Navbar.css";

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [linkItems, setLinkItems] = useState([]);
    const [scrolling, setScrolling] = useState(false); // Track whether the user is scrolling

    // Appends each link to array every 100 miliseconds
    useEffect(() => {
        if (menuOpen) {
            const timeout = setTimeout(() => {
                setLinkItems((prevItems) => {
                    const nextIndex = prevItems.length;
                    if (nextIndex >= 4) {
                        return prevItems;
                    } else {
                        return [...prevItems, nextIndex];
                    }
                });
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [linkItems]);

    // Open/close burger menu
    const toggleMenu = () => {
        setMenuOpen((prevMenuOpen) => !prevMenuOpen);
        if (!menuOpen) {
            setLinkItems([]);
        }
    };

    // Handle scroll event
    const handleScroll = () => {
        // Determine whether the user is scrolling down or not
        const isScrollingDown = window.scrollY > 10; // Adjust the value to control when the background color fades in

        // Set the scrolling state accordingly
        setScrolling(isScrollingDown);
    };

    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener("scroll", handleScroll);

        // Remove scroll event listener when the component unmounts
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <nav className={`nav ${scrolling ? "fade-in-background" : ""}`}>
            <div className="navbar">
                {menuOpen && <div className="background"></div>}
                <Link className="methacks-logo" to="/" onClick={() => {
                    let hero = document.getElementById("hero");
                    hero &&
                        hero.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                }}>
                    <img src={MetHacks} alt="MetHacks LOGO" width="50px" />
                </Link>
                <div className="routes-container">
                    <div className={`routes ${menuOpen ? "active" : ""}`}>
                        <Link
                            className="item"
                            to="/about"
                            onClick={() => {
                                let hero = document.getElementById("about");
                                hero &&
                                    hero.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start",
                                    });
                            }}
                        >
                            About
                        </Link>
                        {/* <Link
                            className="item"
                            to="/team"
                            onClick={() => {
                                let hero = document.getElementById("team");
                                hero &&
                                    hero.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start",
                                    });
                            }}
                        >
                            Team
                        </Link> */}
                        <Link
                            className="item"
                            to="/faq"
                            onClick={() => {
                                let hero = document.getElementById("faq");
                                hero &&
                                    hero.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start",
                                    });
                            }}
                        >
                            FAQ
                        </Link>
                        {/* <Link
                            className="item"
                            to="/sponsors"
                            onClick={() => {
                                let hero = document.getElementById("sponsors");
                                hero &&
                                    hero.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start",
                                    });
                            }}
                        >
                            Sponsors
                        </Link> */}
                    </div>
                    <div className={`hamburger-menu ${menuOpen ? "active" : ""}`} onClick={toggleMenu}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                    {menuOpen && (
                        <nav className="menu">
                            <Link
                                className={`menu-item ${linkItems.includes(0) ? "show" : ""}`}
                                to="/about"
                                onClick={() => {
                                    let hero = document.getElementById("about");
                                    hero &&
                                        hero.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        });
                                    toggleMenu();
                                }}
                            >
                                About
                            </Link>
                            {/* <Link
                                className={`menu-item ${linkItems.includes(1) ? "show" : ""}`}
                                to="/team"
                                onClick={() => {
                                    let hero = document.getElementById("team");
                                    hero &&
                                        hero.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        });
                                    toggleMenu();
                                }}
                            >
                                Team
                            </Link> */}
                            <Link
                                className={`menu-item ${linkItems.includes(2) ? "show" : ""}`}
                                to="/faq"
                                onClick={() => {
                                    let hero = document.getElementById("faq");
                                    hero &&
                                        hero.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        });
                                    toggleMenu();
                                }}
                            >
                                FAQ
                            </Link>
                            {/* <Link
                                className={`menu-item ${linkItems.includes(3) ? "show" : ""}`}
                                to="/sponsors"
                                onClick={() => {
                                    let hero = document.getElementById("sponsors");
                                    hero &&
                                        hero.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        });
                                    toggleMenu();
                                }}
                            >
                                Sponsors
                            </Link> */}
                        </nav>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;